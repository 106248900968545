<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/information'}">信息公开</router-link> > 详情</div>
			<div class="wapper" v-if="detail">
				<!-- <div class="title">{{detail.title}} </div> -->
				<!-- <p>{{detail.gmtCreate}}</p> -->
				<div class="content" v-html="detail.content">
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				index: '',
				detail: null,
			}
		},
		created() {
			this.index = this.$route.query.index
		},
		mounted() {
			this.getBanner()
		},
		methods: {
			getBanner() {
				let formData = new FormData()
					formData.append('type', 0)
				this.$http({
					url: `/bannersApi/queryBannersPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.detail = res.data[this.index]
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #fff;
	}
	.wapper{
		.title{
			text-align: center;
			font-size: 28px;
			padding: 30px 0 20px 0;
		}
		>p{
			color: #ABABAB;
			text-align: center;
			font-size: 16px;
		}
		.content{
			font-size: 16px;
			line-height: 25px;
			padding: 30px 120px;
			::v-deep img{
				max-width: 100% !important;
				display: inline-block;
			}
		}
		
	}
}
	
</style>
